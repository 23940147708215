<template>
	<section>
		<draggable
			class="drag-group" v-model="list" v-bind="dragOptions"
            @start="isDragging = true"
            @end="isDragging = false"
		>
			<transition-group type="transition" :name="!drag ? 'flip-list' : null">
				<div
					class="modules-menu-container"
					v-for="(group, index) in list"
					:key="group"
				>
					<div v-if="group !== 'Triagem Benefício Visão'">
						<div class="flex module-title" :class="{ 'border-top': group !== 'Anexar arquivos' }">
							<div>
								<EyeSlash v-if="hiddenGroup[group]" class="eye-icon slash" @click=handleGroupVisibility(group) />
								<EyeFill v-else class="eye-icon" @click="handleGroupVisibility(group)"/>
								{{ group }} ({{ menu[group].length }})
							</div>
							<ChevronDown class="chevron" v-b-toggle="`collapse-menu-${index}`" />
						</div>
						<b-collapse :visible="false" :id="`collapse-menu-${index}`" class="collapse-wrapper">
							<ModuleMenu
								v-for="formName in menu[group]"
								:key="formName"
								:formName="formName"
							/>
							<AdditionalExamButton v-if="group === 'Exames complementares'" />
						</b-collapse>
					</div>
				</div>
			</transition-group>
		</draggable>
	</section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { addAdditionalExamsFromForms } from '@/utils/attendanceHelper'

export default {
	components: {
		ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
		ModuleMenu: () => import('@/components/Attendance/Menu/Module'),
		AdditionalExamButton: () => import('@/components/Attendance/Menu/AdditionalExamButton'),
		EyeFill: () => import('@/assets/icons/eye-fill.svg'),
		EyeSlash: () => import('@/assets/icons/eye-slash.svg'),
	},
	computed: {

		...mapState({
			groups: state => state.attendance.menu.groups,
			selectedForm: state => state.attendance.form.selected,
			customFormEdit: state => state.attendance.customFormEdit,
			hiddenGroup: state => state.attendance.menu.hiddenGroup
		}),

		dragOptions() {
			return {
				animation: 0,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		}
	},
	data(){
		return {
			menu: {},
			list: [],
			drag: true
		}
	},
	methods: {
		...mapActions('attendance/menu', ['handleGroupVisibility']),
		setFormCustomExams (form) {
			if( !this.customFormEdit) return

			const customExams = form?.filter(el => el?.id?.includes('exame-personalizado'))
			customExams.map(exam => {
				addAdditionalExamsFromForms(exam?.id, exam?.customName ?? 'Exame personalizado', exam.showing)
			})
		},

		handleMenuScopes (type) {
			this.menu = {}
			Object.keys(this.groups).map( group => {
				const section = []
				this.groups[group].map(form => {
					if (
						this.$store.state.attendance?.form[form]?.scopes.find(el => {
							return el === type
						})
					) {
						section.push(form)
					}
				})

				if (section.length)
					this.$set(this.menu, group, section)
				this.list = Object.keys(this.menu)
			})
		}
	},

	watch: {
		selectedForm: {
			handler (value) {
				const type = value && value.type ? value.type : 'ATTENDANCE'
				this.handleMenuScopes(type)
				this.setFormCustomExams(value?.forms)
			},
			deep: true
		}
	}
}
</script>
<style lang="scss" scoped>
.flip-list-move {
	transition: transform 0.5s;
}
.no-move {
	transition: transform 0s;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.modules-menu-container {
	.flex {
		padding: 16px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		gap: 10px;
		.icon {
			width: 24px;
			height: 24px;
			cursor: pointer;
			stroke: var(--neutral-500);
			&.plus {
				fill: var(--blue-500);
				stroke: transparent;
			}
			&.confirmed {
				stroke: var(--states-success);
			}
		}
	}

	.module-title {
		font-weight: 700;
		font-size: min(1.2vw, 14px);
		color: var(--dark-blue);
		white-space: nowrap;
	}

	.module {
		padding: 8px 16px;
		font-weight: 600;
		font-size: 16px;
		color: var(--blue-500);
		cursor: pointer;
		border-left: 4px solid transparent;

		&.active {
			font-weight: 700;
			color: var(--type-active);
			border-left: 4px solid var(--orange);
		}
	}
	.chevron {
		width: 24px;
		height: 24px;
		stroke: var(--neutral-600);
		transition: all 0.5s;
		cursor: pointer;
	
		&.not-collapsed {
			transform: rotate(180deg);
			animation: rotateUp .5s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		&.collapsed {
			transform: rotate(0deg);
			animation: rotateDown .5s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		&.chevron:focus {
			outline: none !important;
		}
	}
}

.border-top {
	border-top: 1px solid var(--neutral-200);
}

.eye-icon{
	cursor: pointer;
	width: 24px;
	height: 24px;
	padding-right: 5px;

	&.slash {
		opacity: 0.65;
	}
}
</style>
